import { faCross, faSignOut, faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons"; // Added chevron icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef, useState } from "react";
import initMenus from "../../data/menus.js";
import "./sidebar.css";
import SidebarLogo from "./SidebarLogo.jsx";
import SidebarSearch from "./SidebarSearch.jsx";
import MenuList from "./MenuList.jsx";
import { useNavigate } from "react-router-dom";

function Sidebar({ ...props }) {
  const navigate = useNavigate();
  const [menus, setMenus] = useState(initMenus);
  const [scButton, setScButton] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const search = useRef("");

  const handleChange = (e) => {
    if (e.target.value) {
      setScButton(true);
      setMenus(
        menus.filter((el) => {
          return el.label.toLowerCase().includes(e.target.value.toLowerCase());
        })
      );
    } else {
      setScButton(false);
      setMenus(initMenus);
    }
  };

  const clearSearch = () => {
    search.current.value = "";
    setMenus(initMenus);
    setScButton(false);
  };

  const logout = () => {
    navigate("/auth/login");
  };

  const toggleSidebar = () => {
    setIsVisible(!isVisible);
  };

  return (
    <div className="flex">
      {isVisible && (
        <aside
          id="sidebar"
          className={`sidebarWrapper md:translate-x-0 -translate-x-full md:z-0 z-50 no-scrollbar ${
            props.className
          } w-64 transition-all duration-300 relative`} // Added relative positioning
        >
          <div className="border-r-2 border-gray-100 h-full flex flex-col items-start">
            {/* Logo */}
            <SidebarLogo toggle={props.toggle} icon={faCross} text="ProTrainingStudio" />
            {/* Search Menu */}
            <SidebarSearch
              clearSearch={clearSearch}
              handleChange={handleChange}
              scButton={scButton}
              search={search}
            />
            {/* Menu */}
            <MenuList menus={menus} toggle={props.toggle} />
            {/* Profile */}
            <div className="pt-2 border-t border-gray-300 w-full">
              <div className="py-2 px-4">
                {/* Logout Button */}
                <button
                  className="py-2 px-4 border border-blue-500 bg-blue-600 w-full rounded-full text-gray-200 hover:bg-blue-600 hover:border-blue-600 justify-end text-sm"
                  onClick={logout}
                >
                  <FontAwesomeIcon icon={faSignOut} /> Logout
                </button>
              </div>
            </div>
          </div>

          {/* New Toggle Button - positioned as a delimiter */}
          <button
            onClick={toggleSidebar}
            className="absolute -right-3 top-1/2 transform -translate-y-1/2 w-6 h-12 bg-gray-100 rounded-r border-r border-t border-b border-gray-200 flex items-center justify-center hover:bg-gray-200 transition-colors"
          >
            <FontAwesomeIcon
              icon={isVisible ? faChevronLeft : faChevronRight}
              className="text-gray-600 text-xs"
            />
          </button>
        </aside>
      )}

      {!isVisible && (
        <button
          onClick={toggleSidebar}
          className="w-6 h-12 bg-gray-100 rounded-r border-r border-t border-b border-gray-200 flex items-center justify-center hover:bg-gray-200 transition-colors mt-[50vh] -translate-y-1/2"
        >
          <FontAwesomeIcon
            icon={faChevronRight}
            className="text-gray-600 text-xs"
          />
        </button>
      )}

      {props.className === "mobile" && isVisible && (
        <div
          id="overlaySidebar"
          onClick={props.toggle}
          className="hidden absolute w-full h-screen bg-black z-10 inset-0 opacity-60"
        >
          <div></div>
        </div>
      )}
    </div>
  );
}

export default Sidebar;
