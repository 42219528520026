import { faPage4, faWindows } from "@fortawesome/free-brands-svg-icons";
import {
  faTachometer,
  faTable,
  faClipboard,
  faNoteSticky,
  faDumbbell
} from "@fortawesome/free-solid-svg-icons";

const initMenu = [
  {
    label: "Dashboard",
    path: "/",
    icon: faTachometer,
  },
  {
    label: 'Services'
  },
  {
    label: "Visit Form",
    path: "/blank",
    icon: faNoteSticky,
  },
  {
    label: "MemberShip Form",
    path: "/404",
    icon: faNoteSticky,
  },

  {
    label: 'Clients Form'
  },
  {
    label: "Phisical Evaluation",
    path: "/form",
    icon: faClipboard,
  },
  {
    label: 'Personal Training'
  },
  {
    label: "Training Plan",
    path: "/table",
    icon: faDumbbell,
  },

  {
    label: 'Conference Form'
  },
  {
    label: "Monthly Track",
    path: "/blank",
    icon: faTable,
  },
  {
    label: "Yearly Track",
    path: "/blank",
    icon: faTable,
  },
];

export default initMenu
