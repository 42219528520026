import React, { useState } from "react";
import { useOutletContext } from "react-router-dom";

function Blank() {
  const [sidebarToggle] = useOutletContext();

  const [formData, setFormData] = useState({
    name: '',
    birthDate: '',
    gender: '',
    occupation: '',
    phone: '',
    email: '',
    taxNumber: '',
    howDidYouHear: '',
    anamnesis: '',
    goal: '',
    consent: false,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    alert("Form submitted successfully!");
    setFormData({
      name: '',
      birthDate: '',
      gender: '',
      occupation: '',
      phone: '',
      email: '',
      taxNumber: '',
      howDidYouHear: '',
      anamnesis: '',
      goal: '',
      consent: false,
    });
  };

  return (
    <>
      <main className="h-full bg-gray-100 p-4 md:p-8">
        <form onSubmit={handleSubmit} className="visit-form max-w-4xl mx-auto bg-white shadow-lg p-8 rounded-lg">
          <h2 className="text-2xl font-bold text-blue-600 mb-6 text-center">Visit Form</h2>

          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {/* Name */}
            <div className="col-span-1">
              <label className="block text-gray-700 mb-1">Name:</label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
                className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-400"
              />
            </div>

            {/* Birth Date */}
            <div className="col-span-1">
              <label className="block text-gray-700 mb-1">Birth Date:</label>
              <input
                type="date"
                name="birthDate"
                value={formData.birthDate}
                onChange={handleChange}
                required
                className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-400"
              />
            </div>

            {/* Gender */}
            <div className="col-span-1">
              <label className="block text-gray-700 mb-1">Gender:</label>
              <select
                name="gender"
                value={formData.gender}
                onChange={handleChange}
                required
                className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-400"
              >
                <option value="">Select</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
              </select>
            </div>

            {/* Occupation */}
            <div className="col-span-1 lg:col-span-2">
              <label className="block text-gray-700 mb-1">Occupation:</label>
              <input
                type="text"
                name="occupation"
                value={formData.occupation}
                onChange={handleChange}
                className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-400"
              />
            </div>

            {/* Phone */}
            <div className="col-span-1">
              <label className="block text-gray-700 mb-1">Mobile Phone:</label>
              <input
                type="tel"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                required
                className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-400"
              />
            </div>

            {/* Email */}
            <div className="col-span-1 lg:col-span-2">
              <label className="block text-gray-700 mb-1">Email:</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-400"
              />
            </div>

            {/* Tax Number */}
            <div className="col-span-1">
              <label className="block text-gray-700 mb-1">Tax Number (NIF):</label>
              <input
                type="text"
                name="taxNumber"
                value={formData.taxNumber}
                onChange={handleChange}
                className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-400"
              />
            </div>

            {/* How did you hear about us? */}
            <div className="col-span-1 lg:col-span-2">
              <label className="block text-gray-700 mb-1">How did you hear about us?</label>
              <input
                type="text"
                name="howDidYouHear"
                value={formData.howDidYouHear}
                onChange={handleChange}
                className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-400"
              />
            </div>

            {/* Anamnesis */}
            <div className="col-span-1 lg:col-span-3">
              <label className="block text-gray-700 mb-1">Anamnesis:</label>
              <textarea
                name="anamnesis"
                value={formData.anamnesis}
                onChange={handleChange}
                className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-400"
              ></textarea>
            </div>

            {/* Goal */}
            <div className="col-span-1 lg:col-span-3">
              <label className="block text-gray-700 mb-1">Goal:</label>
              <textarea
                name="goal"
                value={formData.goal}
                onChange={handleChange}
                className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-400"
              ></textarea>
            </div>

            {/* Consent */}
            <div className="col-span-1 lg:col-span-3">
              <label className="inline-flex items-center text-gray-700">
                <input
                  type="checkbox"
                  name="consent"
                  checked={formData.consent}
                  onChange={handleChange}
                  className="form-checkbox h-5 w-5 text-blue-600"
                />
                <span className="ml-2">I consent to my personal data being processed and receiving communications</span>
              </label>
            </div>
          </div>

          {/* Submit Button */}
          <button
            type="submit"
            className="w-full bg-blue-600 text-white p-3 rounded-lg font-semibold hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 mt-4"
          >
            Submit
          </button>
        </form>
      </main>
    </>
  );
}

export default Blank;

